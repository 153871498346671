import jsPDF from 'jspdf';
import { createContentForPageOne, createContentForPageTwo, createContentForPageThree } from './createPdfContent';
import { addPageOne, addPageTwo, addPageThree } from './addPdfContent';
import { generateCreatedAtValue, clearElement } from './utilities';

async function generateDashboardReport() {
  const container = document.getElementById('outsideElement');
  const pdf = new jsPDF({ format: 'letter' });
  const createdAt = generateCreatedAtValue();

  createContentForPageOne(container);
  createContentForPageTwo(container);
  createContentForPageThree(container);

  await addPageOne(pdf, createdAt);
  await addPageTwo(pdf, createdAt);
  await addPageThree(pdf, createdAt);

  const fileName = 'dashboard-report';
  pdf.save(fileName);
  clearElement(container);
}

export default generateDashboardReport;
