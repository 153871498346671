import { I18n } from 'design-system';
import React from 'react';

import analyticsLogo from '../../../images/analytics-logo-with-text.svg';
import styles from './Header.module.scss';

const Header = () => {
  return (
    <header className={styles.header} data-testid='test-header'>
      <img src={analyticsLogo} alt={I18n.t('lightspeed_analytics')} />
    </header>
  );
};

export default Header;
