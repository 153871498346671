import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const UserContext = React.createContext();
export const UserConsumer = UserContext.Consumer;

export const UserProvider = (props) => {
  const [user, setUser] = useState(props.user);

  return <UserContext.Provider value={{ user, setUser }}>{props.children}</UserContext.Provider>;
};

UserProvider.propTypes = {
  children: PropTypes.object,
  user: PropTypes.object,
};
