import isBefore from 'date-fns/isBefore';
import { useContext } from 'react';

import { UserContext } from '../contexts/UserContext';
import useGravatarImage from './useGravatarImage';

const useAuthUser = () => {
  const { user } = useContext(UserContext);
  const pictureUrl = useGravatarImage(user.email);
  let initials = '';
  if (user.first_name && user.last_name) {
    initials = `${user.first_name.charAt(0)}${user.last_name.charAt(
      0
    )}`.toUpperCase();
  }
  const relayExpiresAt = user?.district?.relayExpiresAt;

  return {
    ...user,
    initials,
    profilePicture: pictureUrl,
    fullName: `${user.first_name} ${user.last_name}`,
    userEmail: user.email,
    hasRelayLicense: relayExpiresAt
      ? isBefore(new Date(), new Date(relayExpiresAt))
      : false,
  };
};

export default useAuthUser;
