import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

const trackingId = process.env.REACT_APP_GOOGLE_ANALYTICS;

const GoogleAnalytics = ({ children }) => {
  const { pathname } = useLocation();
  ReactGA.initialize(trackingId);

  useEffect(() => {
    ReactGA.pageview(pathname);
  }, [pathname]);

  return children;
};

export default GoogleAnalytics;
