export const analyticsEndpoints = {
  dashboard: {
    appCount: '/dashboard/customer_app_count',
    costDistribution: '/dashboard/distribution_of_costs',
    userUsageCount: '/dashboard/user_usage_count',
    totalCost: '/dashboard/customer_total_cost',
    appsByLicenseUsage: '/dashboard/apps_by_license_usage',
    privacyGrade: '/data_privacy',
    userActivityByDay: '/dashboard/user_activity',
    userActivityByHour: '/dashboard/user_activity',
    appsInformation: '/dashboard/apps_information',
    kpiInformation: '/dashboard/kpi_information',
    topAppUsage: '/dashboard/top_app_usage',
    dailyStudentActivity: '/dashboard/daily_student_activity',
    dailyStudentTimeUsageData: '/dashboard/daily_student_time_usage_data',
    topTrendingAppsByActiveStudents: '/dashboard/top_trending_apps_for_students',
    topUnapprovedApps: '/dashboard/top_most_used_unapproved_apps',
    recentlyDiscoveredApps: '/dashboard/recently_discovered_apps',
    identifiedAppsCount: '/dashboard/kpi_identified_apps_count',
  },
  apps: {
    detected: '/detected_apps',
    mostUsers: '/apps/most_users',
    mostActivitybyUsers: '/apps/most_activity_by_users',
    appsByLicenseUsage: '/dashboard/apps_by_license_usage',
    sessionsByDay: '/apps/sessions_by_day',
    submitApp: '/apps/add_app_request',
    status: '/apps/customer_apps/status',
    trackData: '/apps',
    track: (guid) => `/apps/${guid}/track`,
    appData: (guid) => `/apps/customer_apps/${guid}`,
    untrack: (guid) => `/apps/${guid}/untrack`,
    notes: (app_guid, noteId = null) => `/apps/${app_guid}/notes${noteId ? `/${noteId}` : ''}`,
    customRatings: (guid) => `/apps/${guid}/customer_ratings`,
    approvedApps: '/apps/approved-apps',
  },
  groups: {
    activityByUser: '/groups/activity_count_by_user',
  },
  chartBuilder: {
    userActivityByApps: '/chart_builder/user_activity_by_apps',
    userCostByApps: '/chart_builder/user_cost_by_apps',
  },
  districts: {
    root: '/districts',
  },
  appDetail: {
    sessionsByDay: '/apps/sessions_by_day',
  },
  geolocation: {
    trackLocation: '/geolocation/track_location',
    locationDevices: '/geolocation/location_devices',
  },
};
