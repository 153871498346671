import { useEffect, useState } from 'react';
import { generateMD5Hash } from '../../utilities/helper';

export const getGravatarUrl = (email = '') => {
  const md5Hash = generateMD5Hash(email.trim().toLowerCase());
  const pictureUrl = `https://www.gravatar.com/avatar/${md5Hash}?d=404`;
  return pictureUrl;
};

const useGravatarImage = (email) => {
  const [pictureUrl, setPictureUl] = useState();

  useEffect(() => {
    const validateUserPicture = async () => {
      const gravatarUrl = getGravatarUrl(email);
      const response = await fetch(gravatarUrl);

      if (response.status === 200) {
        setPictureUl(gravatarUrl);
      }
    };
    validateUserPicture();
  }, [email]);

  return pictureUrl;
};

export default useGravatarImage;
