import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { AnalyticsAPI } from '../../utilities/AnalyticsAPI';
import { isEmpty } from '../../utilities/helper';
import {
  localStorageLoadState,
  localStorageRemoveState,
  localStorageSaveState,
} from '../../utilities/localStorage';
import { analyticsEndpoints } from './endpoints';

export const AnalyticsContext = React.createContext();
export const AnalyticsConsumer = AnalyticsContext.Consumer;
let timer;

export class AnalyticsProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      groups: {},
      chartBuilderData: {},
      appNotes: [],
      loadinNotes: true,
      getAnalyticsData: this.getAnalyticsData.bind(this),
      resetAnalyticsData: this.resetAnalyticsData.bind(this),
      AppManager: this.AppManager.bind(this),
      customizeRatings: this.customizeRatings.bind(this),
      requestComplianceReview: this.requestComplianceReview.bind(this),
      resetRatings: this.resetRatings.bind(this),
      setAnalyticsState: this.setAnalyticsState.bind(this),
    };
  }

  setAnalyticsState(newState) {
    this.setState(newState);
  }

  setAnalyticsData(path, data) {
    this.setState((state, props) => {
      const stringifiedGroups = JSON.stringify(state.groups);
      const groupState = JSON.parse(stringifiedGroups);
      const lastPathIndex = path.length - 1;

      let currentPointer = groupState;
      path.forEach((key, index) => {
        if (index === lastPathIndex) {
          currentPointer[key] = data;
        } else {
          currentPointer[key] = currentPointer[key] || {};
          currentPointer = currentPointer[key];
        }
      });

      return {
        groups: groupState,
      };
    });
  }

  getAnalyticsData(page, key, options) {
    const { start, end, guid, app_guid, refetch, fetch } = options;

    if (!guid) {
      return undefined;
    }

    if (refetch) {
      return this.fetchAnalyticsData(page, key, options);
    }

    if (
      !app_guid &&
      this.state.groups[`${start}-${end}`] &&
      this.state.groups[`${start}-${end}`][guid] &&
      this.state.groups[`${start}-${end}`][guid][page] &&
      this.state.groups[`${start}-${end}`][guid][page][key]
    ) {
      return this.state.groups[`${start}-${end}`][guid][page][key];
    }

    if (
      app_guid &&
      this.state.groups[`${start}-${end}`] &&
      this.state.groups[`${start}-${end}`][guid] &&
      this.state.groups[`${start}-${end}`][guid][page] &&
      this.state.groups[`${start}-${end}`][guid][page][key] &&
      this.state.groups[`${start}-${end}`][guid][page][key][app_guid]
    ) {
      return this.state.groups[`${start}-${end}`][guid][page][key][app_guid];
    }

    if (fetch) {
      this.fetchAnalyticsData(page, key, options);
    }

    return undefined;
  }

  resetAnalyticsData(page, key, options) {
    const { start, end, guid } = options;

    if (!guid) {
      return undefined;
    }

    this.setState((state, props) => {
      if (
        state.groups[`${start}-${end}`] &&
        state.groups[`${start}-${end}`][guid] &&
        state.groups[`${start}-${end}`][guid][page] &&
        state.groups[`${start}-${end}`][guid][page][key]
      ) {
        delete state.groups[`${start}-${end}`][guid][page][key];
      }

      return state;
    });
  }
  async fetchAnalyticsData(page, key, options = {}) {
    const {
      start,
      end,
      guid,
      guids,
      app_guid,
      timeZone,
      compare,
      timeInterval,
    } = options;
    const url = analyticsEndpoints[page][key];

    // add the date range and time zone to the passed in URL
    const defaultPath = [`${start}-${end}`, guid, page, key];

    const statePath = app_guid ? [...defaultPath, app_guid] : defaultPath;
    const localStoragePath = app_guid
      ? [...defaultPath, app_guid]
      : defaultPath;
    let guidParam = `&guid=${guid}`;
    if (guids && guids.length) {
      guids.forEach((id) => {
        guidParam += `&guids=${id}`;
      });
    }

    let newUrl = `${url}${
      url.includes('?') ? '&' : '?'
    }start_date=${start}&end_date=${end}${guidParam}`;
    newUrl = timeZone ? `${newUrl}&time_zone=${timeZone}` : newUrl;
    newUrl = timeInterval ? `${newUrl}&time_interval=${timeInterval}` : newUrl;

    try {
      const data = await AnalyticsAPI.recursiveFetch(
        newUrl,
        { headers: { cid: options.customerId } },
        {
          onInit: () => {
            // add queryExecutionId if saved in localStorage
            const queryExecutionId = localStorageLoadState(
              'savedQueries',
              start,
              end,
              localStoragePath
            );
            let endpoint = queryExecutionId
              ? `${newUrl}&queryExecutionId=${queryExecutionId}`
              : newUrl;
            endpoint = app_guid ? `${endpoint}&app_guid=${app_guid}` : endpoint;
            return endpoint;
          },
          onQueued: (response) => {
            localStorageSaveState(
              'savedQueries',
              start,
              end,
              localStoragePath,
              response.queryExecutionId
            );
          },
          onRetry: () =>
            localStorageRemoveState(
              'savedQueries',
              start,
              end,
              localStoragePath
            ),
        }
      );

      localStorageRemoveState('savedQueries', start, end, localStoragePath);
      if (page === 'chartBuilder') {
        data.compare = compare;
        this.setState({ chartBuilderData: data });
      } else {
        const isPrivacyGrade = key === 'privacyGrade';
        let appsWithRatings = [];

        if (isPrivacyGrade && data.results) {
          appsWithRatings = data.results.apps.map((app) => {
            const sourceRating = `${
              isEmpty(app.organization_ratings) ? 'default' : 'organization'
            }_ratings`;
            return {
              ...app,
              ...app[sourceRating],
            };
          });
        }

        this.setAnalyticsData(
          statePath,
          isPrivacyGrade ? { apps: appsWithRatings } : data.results
        );
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      if (page === 'chartBuilder') {
        this.setState({ chartBuilderData: { state: 'FAIL' } });
      }
    }
  }

  AppManager({ params, options, selectedApps }) {
    const { start, end, guid, customerId } = options;
    const headers = { headers: { cid: customerId } };
    let currentApps = [];
    const group = this.state.groups[`${start}-${end}`] || {};
    const { apps } = group[guid] || {};
    if (apps) {
      currentApps = apps.detected;
    }

    const trackUrl = analyticsEndpoints.apps.track;
    const unTrackUrl = analyticsEndpoints.apps.untrack;
    const trackDataUrl = analyticsEndpoints.apps.trackData;

    const copyOfCurrentApps = Array.isArray(currentApps)
      ? [...currentApps]
      : [];
    const currentApp = copyOfCurrentApps.find(
      (app) => app.guid === params.app_guid
    );

    const updateDetectedApps = (updatedApps) => {
      const updatedState = {
        [`${start}-${end}`]: { [guid]: { apps: { detected: updatedApps } } },
      };
      this.setState({ groups: updatedState });
    };

    const updateAppData = (appData) => {
      if (currentApp) {
        currentApp.license_type = appData.type;
        currentApp.renewal_date = appData.renewal_date;
        updateDetectedApps(copyOfCurrentApps);
      }
    };

    const updateTrackState = (response, isTracked) => {
      if (response.state === 'SUCCEEDED' && currentApp) {
        currentApp.is_tracked = isTracked;
        updateDetectedApps(copyOfCurrentApps);
        return true;
      }
      return false;
    };

    const updateAppStatus = async () => {
      try {
        let appStatusUrl = analyticsEndpoints.apps.status;
        appStatusUrl += `?start_date=${start}&end_date=${end}`;
        if (selectedApps && selectedApps.length) {
          selectedApps.forEach((app) => (appStatusUrl += `&guids=${app}`));
        }
        const data = await AnalyticsAPI.put(appStatusUrl, params, headers);
        if (data.state === 'SUCCEEDED') {
          if (copyOfCurrentApps.length) {
            selectedApps.forEach((app) => {
              const current = copyOfCurrentApps.find((c) => c.guid === app);
              current.state = params.status;
            });
            updateDetectedApps(copyOfCurrentApps);
            return data.state;
          }
        }
        return false;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };

    const track = async () => {
      try {
        const response = await AnalyticsAPI.put(
          trackUrl(params.app_guid),
          options,
          headers
        );
        return updateTrackState(response, true);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        return false;
      }
    };

    const unTrack = async () => {
      try {
        const response = await AnalyticsAPI.put(
          unTrackUrl(params.app_guid),
          options,
          headers
        );
        if (response.state === 'SUCCEEDED') {
          updateTrackState(response, false);
        }
        return true;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        return false;
      }
    };

    const updateTrackData = async () => {
      try {
        const response = await AnalyticsAPI.post(trackDataUrl, params, headers);
        if (response.success) {
          updateAppData(params);
          return true;
        }
        return false;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        return false;
      }
    };

    return {
      track,
      unTrack,
      updateAppStatus,
      updateTrackData,
    };
  }

  async customizeRatings(appGuid, ratings, options) {
    try {
      const response = await AnalyticsAPI.put(
        analyticsEndpoints.apps.customRatings(appGuid),
        ratings,
        {
          headers: { cid: options.customerId },
        }
      );

      if (response) {
        this.updateDataComplianceTable(appGuid, ratings, options);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  async resetRatings(appGuid, defaults, options) {
    try {
      const response = await AnalyticsAPI.delete(
        analyticsEndpoints.apps.customRatings(appGuid),
        {},
        { headers: { cid: options.customerId } }
      );

      if (response) {
        this.updateDataComplianceTable(appGuid, defaults, options, true);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  async requestComplianceReview(app, user, options) {
    const body = {
      app_guid: app.guid,
      app_name: app.name,
      website: app.developer_website,
      type: 'review_privacy',
      email: user.email,
    };

    const response = (success, message = '') => ({
      success,
      message,
    });

    const headers = {
      headers: {
        cid: options.customerId,
      },
    };

    try {
      const res = await AnalyticsAPI.post(
        analyticsEndpoints.apps.submitApp,
        body,
        headers
      );

      if (res.success) {
        return response(true);
      }
      return response(false);
    } catch (error) {
      return response(false, error.toString());
    }
  }

  updateDataComplianceTable(appGuid, ratings, options, onReset = false) {
    const { start, end, guid } = options;
    const { apps } =
      this.state.groups[`${start}-${end}`][guid].dashboard.privacyGrade;

    const privacyGradeCopy = [...apps];
    const currentApp = privacyGradeCopy.find((app) => app.guid === appGuid);
    if (onReset) {
      currentApp.default_ratings = ratings;
      delete currentApp.compliance;
    }
    currentApp.organization_ratings = onReset ? {} : ratings;
    currentApp.last_updated_by_name = onReset ? null : ratings.user_name;
    currentApp.privacy_last_updated_ms = onReset ? null : new Date().getTime();
    const categoryRatings = Object.keys(ratings);

    if (categoryRatings.length <= 1) {
      delete currentApp.data_retention;
      delete currentApp.information_usage;
      delete currentApp.privacy_policy;
      delete currentApp.data_security;
    } else {
      categoryRatings.forEach((category) => {
        if (
          category !== 'user_name' ||
          category !== 'last_updated_by_name' ||
          category !== 'privacy_last_updated_ms'
        ) {
          currentApp[category] = ratings[category];
        }
      });
    }
  }

  render() {
    return (
      <AnalyticsContext.Provider value={this.state}>
        {this.props.children}
      </AnalyticsContext.Provider>
    );
  }
}

AnalyticsProvider.propTypes = {
  children: PropTypes.object,
};
