/* eslint-disable multiline-ternary */
import React from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';
import I18n from '../../../utilities/i18n';

export const GroupList = ({ isLoading, groups = [], onSelectGroup, selected }) => {
  const handleSetSelectedGroup = (group) => {
    onSelectGroup(group);
  };

  if (isLoading) {
    return <li className={styles.itemListLoading} aria-label={I18n.t('groups_loading')} />;
  }

  if (!groups || groups.length === 0) {
    return <li className={styles.itemListNoGroups}>{I18n.t('no_groups_found')}</li>;
  }

  return groups.map((group) => {
    const itemClass = classNames(styles.itemList, {
      [styles.selected]: selected && selected.guid === group.guid,
    });
    return (
      <li
        key={group.guid}
        className={itemClass}
        onClick={() => handleSetSelectedGroup(group)}
        role='button'
        tabIndex='0'
      >
        {group.name}
      </li>
    );
  });
};
