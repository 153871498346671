import { setPdfImage, imgPositions, imgWidth } from './utilities';
import logo from '../../../../images/analytics-logo.png';

/*
 * Add individual elements
 */
function addLogo(pdf, positionY = 5) {
  pdf.addImage(logo, 'PNG', 5, positionY, 70, 10, '', 'FAST');
}

function addCreatedAt(pdf, date) {
  const rgbColor = [128, 128, 128];
  const positionX = 210;
  const positionY = 10;

  pdf.setTextColor(...rgbColor);
  pdf.setFontSize(9);
  pdf.text(date, positionX, positionY, { align: 'right' });
}

function addDropdowns(pdf, element) {
  return setPdfImage(pdf, element, { y: 25 });
}

function addKpiBanner(pdf, element) {
  return setPdfImage(pdf, element, { y: 45 });
}

function addTopAppUsage(pdf, element) {
  return setPdfImage(pdf, element, { y: 65 });
}

function addGenericCharts(pdf, element) {
  return setPdfImage(pdf, element, { y: 155 });
}

/*
 * Add pages
 */

export async function addPageOne(pdfInstance, createdAt) {
  addLogo(pdfInstance);
  addCreatedAt(pdfInstance, createdAt);

  const pageEl = document.getElementById('page1');

  await addDropdowns(pdfInstance, pageEl.children[0]);
  await addKpiBanner(pdfInstance, pageEl.children[1]);
  await addTopAppUsage(pdfInstance, pageEl.children[2]);
  await addGenericCharts(pdfInstance, pageEl.children[3]);
}

export async function addPageTwo(pdfInstance, createdAt) {
  pdfInstance.addPage();
  addCreatedAt(pdfInstance, createdAt);

  const pageEl = document.getElementById('page2');
  await setPdfImage(pdfInstance, pageEl.firstChild);
}

export async function addPageThree(pdfInstance, createdAt) {
  pdfInstance.addPage();
  const pageEl = document.getElementById('page3');

  addCreatedAt(pdfInstance, createdAt);

  const { imgData, imgHeight } = await setPdfImage(pdfInstance, pageEl.firstChild);
  addPageForLargeContent(pdfInstance, { imgData, imgHeight });
}

export function addPageForLargeContent(pdfInstance, dataFromPrevContent) {
  const { imgHeight, imgData } = dataFromPrevContent;

  const pageHeight = 295;
  let { y: positionY } = imgPositions;
  let heightLeft = imgHeight;

  positionY += heightLeft - imgHeight;

  heightLeft -= pageHeight;

  while (heightLeft >= 0) {
    positionY += heightLeft - imgHeight;
    pdfInstance.addPage();
    pdfInstance.addImage(imgData, 'PNG', imgPositions.x, positionY + 18, imgWidth, imgHeight - 20, '', 'FAST');
    heightLeft -= pageHeight;
  }
}
