import React, { Fragment } from 'react';
import enTranslations from './i18n.en.js';

const translations = {
  en: enTranslations,
};

const current_locale = 'en';
const currentTranslations = translations[current_locale];

const I18n = {
  translations: currentTranslations,
  t: function (name, params) {
    let string = currentTranslations[name];

    const parseURL = (str, key) => {
      if (typeof str !== 'string') {
        return str;
      }

      // replace content between brackets with link
      const linkMatches = str.match(/\[(.*?)\]\((.*?)\)/);

      if (linkMatches) {
        const linkParts = str.split(linkMatches[0]);
        const link = (
          <a href={linkMatches[2]} title={linkMatches[2]}>
            {linkMatches[1]}
          </a>
        );

        return (
          <Fragment key={key}>
            {linkParts[0]}
            {link}
            {linkParts[1]}
          </Fragment>
        );
      }

      return str;
    };

    if (string && params) {

      for (var param in params) {
        string = string.replace('%{' + param + '}', params[param]);
      }

    } else if (!string) {
      string = <span className='translationNotFound'>{name}</span>;
    }

    // replace content between asterisks with bolded text
    if (typeof (string) === 'string') {
      let stringParts = string.split('*');
      let numStringParts = stringParts.length;
      if (numStringParts >= 3 && numStringParts % 2 === 1) {
        string = stringParts.map(function (stringPart, i) {
          if (i % 2 === 0) {
            return parseURL(stringPart, i);
          }

          return (
            <strong key={i}>  {/* eslint-disable-line react/no-array-index-key */}
              {parseURL(stringPart, `${i}url`)}
            </strong>
          );
        });
      }
    }

    string = parseURL(string);

    return string;
  },
};

export default I18n;