import { sortBy, sortOrderBy } from './sort';
import MD5 from 'crypto-js/md5';
/* eslint-disable no-console */
const isEmpty = (value) => {
  return (
    value === undefined ||
    value === null ||
    value.size === 0 ||
    value.length === 0 ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
};

const getAppIconURL = (app) => {
  if (!isEmpty(app.icon_url)) {
    return app.icon_url;
  }
  if (!isEmpty(app.developer_website)) {
    return 'https://www.google.com/s2/favicons?domain=' + app.developer_website;
  }
};

const capitalizeFirst = (value) => {
  if (value && typeof value === 'string') {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
};

const truncate = (text, maxLength = 25) => {
  if (typeof text === 'string' && text.length) {
    return text.length >= maxLength ? text.slice(0, maxLength) + '...' : text;
  }
};

const addApostrophe = (name) => {
  const apostrophe = name.charAt(name.length - 1).toLowerCase() !== 's' ? '’s' : '’';
  return name + apostrophe;
};

const askAgainState = () => {
  let state;
  const key = 'askAgainState';

  /*
    include the initial state of future modals or dialogs that will have the feature "ask again"
  */
  const initial = {
    requestReview: true,
  };

  const setItem = (data) => {
    localStorage.setItem(key, JSON.stringify(data));
  };

  try {
    state = localStorage.getItem(key);
    if (!state) {
      setItem(initial);
      state = initial;
    }
  } catch (error) {
    console.error(error);
    state = initial;
  }

  if (typeof state === 'string') {
    state = JSON.parse(state);
  }

  return {
    get: () => state,
    set: (modalName, status) => {
      const newState = {
        ...state,
        [modalName]: status,
      };
      try {
        setItem(newState);
      } catch (error) {
        console.error(error);
      }
    },
  };
};

/**
 * @param {boolean} strict
 * if true checks if the objects has the same values in the same order,
 * if false (default) only checks if each key has the same value
 */
const compareObjValues = (obj1, obj2, strict = false) => {
  if (obj1 && obj2 && typeof obj1 === 'object' && typeof obj2 === 'object') {
    if (strict) {
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    }

    for (const key in obj1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }

    return true;
  }
  return false;
};

const sortApps = (state, key, isNumber = false) => {
  if (state && state.apps) {
    const orderBy = Array.isArray(key) ? key[0] : key;
    const order = sortOrderBy(state, orderBy);
    return {
      ...state,
      sortBy: orderBy,
      sortOrder: order,
      apps: sortBy(state.apps, key, order, isNumber),
    };
  }
};

const formatNumberTick = (tickItem) => {
  if (tickItem >= 1000) {
    return `${Math.round(tickItem / 100) / 10}K`;
  }
  return tickItem;
};

const generateMD5Hash = (str) => MD5(str).toString();

const removeSpaceBetweenWords = (str = '') => {
  let newStr = str;
  if (typeof str !== 'string') {
    newStr = String(str);
  }

  return newStr.toString().replace(/\s+/g, '');
};

const stringifyQueryParams = (queryParams = {}) => {
  let params = [];
  const names = Object.keys(queryParams);
  names.forEach((key) => params.push([`${key}=${queryParams[key]}`]));
  return params.length > 0 ? `?${params.join('&')}` : '';
};

export {
  isEmpty,
  getAppIconURL,
  capitalizeFirst,
  truncate,
  addApostrophe,
  askAgainState,
  compareObjValues,
  sortApps,
  formatNumberTick,
  generateMD5Hash,
  removeSpaceBetweenWords,
  stringifyQueryParams,
};
