import { DistrictContext } from 'components/contexts/DistrictContext';
import {
  CheckmarkAltIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  cn,
  WarningIcon,
} from 'design-system';
import PropTypes from 'prop-types';
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { truncate } from 'utilities/helper';
import I18n from 'utilities/i18n';

import styles from './Select.module.scss';

const Select = ({
  defaultValue,
  options = [],
  optionActions = [],
  actions = [],
  label,
  firstOption,
  inversedMode = false,
  newGroup,
  storageKey,
}) => {
  const { setCurrent, appDetailsGroup } = useContext(DistrictContext);
  const [selection, setSelection] = useState(defaultValue);
  const [open, setOpen] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isTouchScreen, setIsTouchScreen] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState();
  const containerRef = useRef();
  const location = useLocation();
  const pathArr = location.pathname.split('/');

  const getNewPath = (groupGuid) => {
    const pathName = [...pathArr];
    pathName.splice(1, 1, groupGuid);
    return pathName.join('/');
  };

  useEffect(() => {
    setIsTouchScreen('ontouchstart' in document.documentElement);
    document.addEventListener('click', triggerClick);
    document.addEventListener('keydown', escPressed);
    return () => {
      document.removeEventListener('click', triggerClick);
      document.removeEventListener('keydown', escPressed);
    };
  }, []);

  useEffect(() => {
    const savedGroupId = localStorage.getItem(storageKey);
    const savedGroup = options.find(({ guid }) => guid === savedGroupId);
    setSelection(savedGroup ? savedGroup : firstOption);
  }, [firstOption, options]);

  useEffect(() => {
    if (newGroup.guid) {
      saveSelectedGroup(newGroup);
    }
  }, [newGroup.guid]);

  const saveSelectedGroup = (group) => {
    localStorage.setItem(storageKey, group.guid);
    setSelection(group);
  };

  const triggerClick = ({ target }) => {
    if (containerRef.current.contains(target)) {
      return;
    }
    setOpen(false);
  };

  const escPressed = ({ keyCode }) => {
    if (keyCode === 27) {
      setOpen(false);
    }
  };

  const getSelectionValues = (selected) => {
    return selected && options.find(({ guid }) => guid === selected);
  };

  const onItemSelected = (selected) => {
    const optionSelected = getSelectionValues(selected);
    if (pathArr[2] === 'apps') {
      setSelection(optionSelected);
    } else {
      saveSelectedGroup(optionSelected ? optionSelected : firstOption);
    }
    setCurrent(selected);
    setOpen(false);
  };

  const onDeleteConfirmation = (event, guid) => {
    const group = options.find((item) => item.guid === guid);
    event.stopPropagation();
    setGroupToDelete(group);
    setShowConfirmation(true);
    setOpen(false);
  };

  const onDeleteCancel = () => {
    setShowConfirmation(false);
    setGroupToDelete(undefined);
  };

  const onDeleteAction = (event) => {
    const { action } = optionActions.find(
      ({ actionName }) => actionName === 'delete'
    );
    action(event, groupToDelete.guid);
    onDeleteCancel();
  };

  const getOptions = () => {
    return (
      options.length > 0 &&
      options.map(({ guid, name }) => (
        <option key={guid} value={guid}>
          {name}
        </option>
      ))
    );
  };

  const checked = (guid) => (
    <div className={styles.checkContainer}>
      {selection && selection.guid === guid && (
        <CheckmarkAltIcon className={styles.checked} />
      )}
    </div>
  );

  const getListItems = () => {
    return (
      options.length > 0 &&
      options
        .filter(({ guid }) => guid !== appDetailsGroup?.guid)
        .map(({ guid, name }) => (
          <li key={guid} className={styles.listItem}>
            {checked(guid)}
            <Link
              to={getNewPath(guid)}
              onClick={() => onItemSelected(guid)}
              className={styles.listItemLink}
            >
              {name}
            </Link>

            <div className={styles.itemActionContainer}>
              {getItemActions(guid)}
            </div>
          </li>
        ))
    );
  };

  const getItemActions = (guid) => {
    const itemActionStyles = cn(styles.itemAction, {
      [styles.itemActionInTouch]: isTouchScreen,
    });
    return (
      optionActions.length > 0 &&
      optionActions.map(({ icon }) => (
        <button
          key={guid}
          onClick={(event) => onDeleteConfirmation(event, guid)}
          className={itemActionStyles}
        >
          {icon}
        </button>
      ))
    );
  };

  const getActions = () => {
    return (
      actions.length > 0 &&
      actions.map(({ text, action, icon }, index) => {
        return (
          <button
            key={`${text + index}`}
            className={cn(styles.action, styles.hideOnMobile)}
            onClick={action}
          >
            {icon}
            {text}
          </button>
        );
      })
    );
  };

  const selectClass = cn(styles.box, {
    'color--gray': Boolean(open),
  });

  const confirmation = () => {
    const confirmationClasses = cn(styles.confirmation, {
      [styles.confirmationUp]: inversedMode,
    });
    return (
      <div className={confirmationClasses}>
        <div className={styles.messageWrapper}>
          <WarningIcon className={styles.icon} />
          <span className={styles.message}>
            {I18n.t('are_you_sure_you_want_to_remove', {
              groupName: truncate(groupToDelete.name),
            })}
          </span>
        </div>
        <div className={styles.actionsWrapper}>
          <button onClick={onDeleteAction} className={styles.confirm}>
            {I18n.t('remove')}
          </button>
          <button onClick={onDeleteCancel} className={styles.cancel}>
            {I18n.t('keep')}
          </button>
        </div>
      </div>
    );
  };

  const listComponent = () => {
    const listClasses = cn(styles.list, {
      [styles.listInversed]: inversedMode,
    });

    return (
      <div className={listClasses} style={{ display: open ? 'block' : 'none' }}>
        <ul className={styles.listItems}>
          {firstOption && (
            <li className={styles.listItem}>
              {checked(firstOption.guid)}
              <Link
                to={getNewPath(firstOption.guid)}
                onClick={() => onItemSelected(firstOption.guid)}
                className={styles.listItemLink}
              >
                {firstOption.name}
              </Link>
            </li>
          )}

          {getListItems()}
        </ul>

        <div className={styles.actions}>{getActions()}</div>
      </div>
    );
  };

  return (
    <Fragment>
      <div className='position--relative'>
        {showConfirmation && confirmation()}
        <select
          className='visually-hidden'
          value={selection ? selection.guid : ''}
          readOnly
        >
          {firstOption && (
            <option value={firstOption.guid} className={styles.listItem}>
              {firstOption.name}
            </option>
          )}
          {getOptions()}
        </select>
      </div>

      <div className={styles.customSelect} ref={containerRef}>
        <button className={selectClass} onClick={() => setOpen(!open)}>
          {selection ? truncate(selection.name, 25) : label}
          {!open ? (
            <ChevronDownIcon className={styles.caretIcon} />
          ) : (
            <ChevronUpIcon className={styles.caretIcon} />
          )}
        </button>
        {listComponent()}
      </div>
    </Fragment>
  );
};

Select.propTypes = {
  firstOption: PropTypes.object,
  options: PropTypes.array,
  actions: PropTypes.array,
  optionActions: PropTypes.array,
  defaultValue: PropTypes.number,
  label: PropTypes.string,
  inversedMode: PropTypes.bool,
  newGroup: PropTypes.object,
  storageKey: PropTypes.string,
};

export default Select;
