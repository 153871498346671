import { DistrictContext } from 'components/contexts/DistrictContext';
import { CloseThickIcon, PlusIcon } from 'design-system';
import PropTypes from 'prop-types';
import React, { Fragment, useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import I18n from 'utilities/i18n';

import AddGroupModal from '../AddGroupModal';
import Select from '../Select/Select';

const GroupPickerDropdown = ({ confirmationUp = false }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const {
    getCurrent,
    setCurrent,
    savedGroups,
    resetCurrent,
    addSavedGroup,
    removeSavedGroup,
    appDetailsGroup,
    getCurrentDistrict,
  } = useContext(DistrictContext);
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);
  const [addedGroup, setAddedGroup] = useState({ guid: undefined });

  const updateUrlGroupId = (guid) => {
    const path = pathname.split('/');
    path[1] = guid;
    navigate(path.join('/'));
  };

  const deleteFavGroup = (event, groupGuid) => {
    event.stopPropagation();
    const current = getCurrent();
    if (groupGuid === current.guid) {
      resetCurrent();
      const { guid } = getCurrentDistrict();
      setCurrent(guid);
      updateUrlGroupId(guid);
    }
    removeSavedGroup(groupGuid);
    setIsGroupModalOpen(false);
  };

  const saveFavGroup = (newGroup) => {
    resetCurrent();
    setAddedGroup(newGroup);
    addSavedGroup(newGroup);
    setIsGroupModalOpen(false);
    setCurrent(newGroup.guid);
    updateUrlGroupId(newGroup.guid);
  };

  const currentGroup = getCurrent();
  const label = currentGroup ? currentGroup.name : I18n.t('select_a_group');
  const currentDistrict = getCurrentDistrict()
    ? getCurrentDistrict()
    : { guid: undefined };

  return (
    <Fragment>
      <Select
        label={label}
        firstOption={appDetailsGroup || currentDistrict}
        options={savedGroups}
        newGroup={addedGroup}
        inversedMode={confirmationUp}
        storageKey='selectedGroup'
        optionActions={[
          {
            action: deleteFavGroup,
            icon: <CloseThickIcon />,
            actionName: 'delete',
          },
        ]}
        actions={[
          {
            text: I18n.t('add_favorite'),
            icon: (
              <PlusIcon className='m-right--8 m-left--16 color--teal fontSize--16' />
            ),
            action: () => setIsGroupModalOpen(true),
          },
        ]}
      />
      {isGroupModalOpen && (
        <AddGroupModal
          save={saveFavGroup}
          open={isGroupModalOpen}
          close={() => setIsGroupModalOpen(false)}
        />
      )}
    </Fragment>
  );
};

GroupPickerDropdown.propTypes = {
  confirmationUp: PropTypes.bool,
};

export default GroupPickerDropdown;
