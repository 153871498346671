import React from 'react';
import PropTypes from 'prop-types';
import styles from './Avatar.module.scss';
import classNames from 'classnames';
import useAuthUser from '../../hooks/useAuthUser';

const Avatar = ({ className, ...props }) => {
  const { fullName, profilePicture, initials } = useAuthUser();
  const avatarClasses = classNames(styles.avatar, className);

  if (profilePicture) {
    return <img className={avatarClasses} src={profilePicture} alt={fullName} {...props} />;
  }

  return <div className={avatarClasses}>{initials}</div>;
};

Avatar.propTypes = {
  className: PropTypes.string,
};

export default Avatar;
