export const sortOrderTypes = {
  ASC: 'asc',
  DESC: 'desc',
};

export function sortBy(data, key, order, isInt) {
  const lowerCaseIfNotInt = (value) => {
    if (typeof value !== 'string') {
      return value;
    }
    return isInt ? value : value.toLowerCase();
  };

  if (data) {
    return data.sort((a, b) => {
      if (Array.isArray(key)) {
        a = lowerCaseIfNotInt(a[key[0]]);
        b = lowerCaseIfNotInt(b[key[0]]);

        key.forEach((k, index) => {
          if (a && index > 0) {
            a = lowerCaseIfNotInt(a[key[index]]);
          }

          if (b && index > 0) {
            b = lowerCaseIfNotInt(b[key[index]]);
          }
        });
      } else {
        a = lowerCaseIfNotInt(a[key]);
        b = lowerCaseIfNotInt(b[key]);
      }

      if (isInt) {
        a = a ? parseInt(a, 10) : 0;
        b = b ? parseInt(b, 10) : 0;
      }
      // eslint-disable-next-line curly
      if (a < b) return order === 'asc' ? -1 : 1;
      // eslint-disable-next-line curly
      if (a > b) return order === 'asc' ? 1 : -1;
      return 0;
    });
  }
  return [];
}

export function sortOrderBy(state, key) {
  if (state.sortBy !== key) {
    return 'asc';
  }
  return state.sortOrder === 'asc' ? 'desc' : 'asc';
}

export function filterSet(items = [], filterTerms) {
  return items.filter((item) => {
    const appName = item.name.toLowerCase();
    const keywords = filterTerms.toLowerCase().split(' ');
    return keywords.every((keyword) => appName.includes(keyword));
  });
}

export function sortByDate(data = [], field = '', sortOrder, isUnixTimestamp) {
  const sorted = data.sort((a, b) => {
    let dateA = a[field];
    let dateB = b[field];

    if (!dateA) {
      return 1;
    }
    if (!dateB) {
      return -1;
    }
    if (dateA === dateB) {
      return 0;
    }

    if (isUnixTimestamp) {
      dateA = converUnixTimestampToDate(dateA);
      dateB = converUnixTimestampToDate(dateB);
    } else {
      dateA = new Date(dateA);
      dateB = new Date(dateB);
    }

    if (sortOrder === sortOrderTypes.DESC) {
      return dateB - dateA;
    }

    return dateA - dateB;
  });

  return sorted;
}

export function converUnixTimestampToDate(unixTimestamp) {
  const milliseconds = parseInt(unixTimestamp || 0, 10);
  return new Date(milliseconds);
}

export function sortingCaseInsensitive(rowA, rowB, columnId) {
  const valueA = rowA.values[columnId].toUpperCase();
  const valueB = rowB.values[columnId].toUpperCase();
  return valueA > valueB ? 1 : -1;
}

export function sortNumericColumns(rowA, rowB, columnId) {
  const valueA = rowA.values[columnId];
  const valueB = rowB.values[columnId];
  return parseInt(valueA, 10) > parseInt(valueB, 10) ? 1 : -1;
}
