import classNames from 'classnames';
import {
  CloseThickIcon,
  MinusIcon,
  Modal,
  PlusIcon,
  SpinnerLoader,
} from 'design-system';
import PropTypes from 'prop-types';
import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { Collapse } from 'react-collapse';

import { AnalyticsAPI } from '../../../utilities/AnalyticsAPI';
import I18n from '../../../utilities/i18n';
import { DistrictContext } from '../../contexts/DistrictContext';
import useDebounce from '../../hooks/useDebounce';
import useOnScreen from '../../hooks/useOnScreen';
import { GroupList } from './GroupList';
import styles from './index.module.scss';
import SelectedGroup from './SelectedGroup';
import reducer, { initialState } from './store/reducer';

const AddGroupModal = ({ open, close, save }) => {
  const { getCurrentDistrict, customerId } = useContext(DistrictContext);
  const district = getCurrentDistrict();
  const groupListItemRef = useRef();
  const [isResetEnabled, setResetEnabled] = useState(true);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: 'setIsLoadingPage', value: false });
  }, [state.groups]);

  const lastLiOnScreen = useOnScreen(groupListItemRef, '50%');
  const hasNextPage = state.groups && state.groups.length < state.userCount;
  useEffect(() => {
    if (
      lastLiOnScreen &&
      !state.isLoading &&
      !state.isLoadingPage &&
      hasNextPage
    ) {
      dispatch({ type: 'setURL', param: 'nextPage' });
      dispatch({ type: 'setIsLoadingPage', value: true });
    }
  }, [lastLiOnScreen, hasNextPage, state.isLoading, state.isLoadingPage]);

  // It's easier to debounce the search value than it is the funciton.
  // We'll use debouncedSearch to kick off a useEffect.
  const debouncedSearch = useDebounce(state.searchValue, 500);
  useEffect(() => {
    dispatch({ type: 'setURL', param: 'query' });
  }, [debouncedSearch]);

  // For the min and max range inputs
  const debouncedRange = useDebounce(state.rangeValues, 750);
  useEffect(() => {
    if (state.groups) {
      dispatch({ type: 'setURL', param: 'rangeValues' });
    }
  }, [debouncedRange]);

  // For the group type filters
  useEffect(() => {
    if (state.selectedGroupTypes) {
      dispatch({ type: 'setURL', param: 'groupTypes' });
    }
  }, [state.selectedGroupTypes]);

  useEffect(() => {
    getGroups();
  }, [state.url, customerId]);

  useEffect(() => {
    if (
      (state.selectedGroupTypes && state.selectedGroupTypes.length) ||
      state.rangeValues.min ||
      state.rangeValues.max
    ) {
      setResetEnabled(false);
    } else {
      setResetEnabled(true);
    }
  }, [state.selectedGroupTypes, state.rangeValues]);

  const getGroups = async () => {
    if (state.url && customerId) {
      try {
        const data = await AnalyticsAPI.get(state.url, {
          headers: { cid: customerId },
        });

        setTimeout(
          () =>
            dispatch({
              type: 'setGroups',
              groups: data.groups,
              count: data.count,
              lastPage: data.total_pages,
            }),
          1000
        );
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    }
  };

  const getGroupByName = async (group) => {
    dispatch({ type: 'resetSelectedGroup' });
    try {
      const data = await AnalyticsAPI.get(
        `${state.baseURL}?query=${group.name}`,
        { headers: { cid: customerId } }
      );
      setTimeout(
        () => dispatch({ type: 'setSelectedGroup', group: data.groups[0] }),
        1000
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const buildGroupTypeButtons = () => {
    return ['Ou', 'Group', 'School'].map((name) => {
      const isSelected = state.selectedGroupTypes && state.selectedGroupTypes.includes(name);
      const classes = classNames(styles.groupTypeButton, {
        [styles.groupTypeSelected]: isSelected,
      });
      return (
        <button
          key={name}
          className={classes}
          onClick={() =>
            dispatch({ type: 'toggleGroupTypeFilter', name: name })
          }
        >
          {I18n.t(name)}
        </button>
      );
    });
  };

  const filterButtonClasses = classNames({
    button: true,
    'button--blue': true,
    [styles.filterButton]: true,
    [styles.filterButtonOpen]: state.filterOpen,
  });

  const resetButtonClasses = classNames(styles.resetFiltersButton, {
    [styles.resetButtonDisabled]: isResetEnabled,
  });

  const setSelectedGroup = (group) => {
    dispatch({ type: 'setSelectedGroup', group });
  };

  return (
    <Modal
      show={open}
      title={I18n.t('add_group')}
      classes={styles.container}
      closeModal={close}
      showCloseButton={false}
      hideActions
      fullWidth
    >
      <section className={styles.contentContainer}>
        {district && (
          <h3 className='m-bottom--16 fontSize--20'>{district.name}</h3>
        )}

        <header className={styles.contentHeader}>
          <div className='l-flex'>
            <input
              className={styles.searchInput}
              placeholder={I18n.t('search_by_group_keyword')}
              value={state.searchValue}
              onChange={(e) =>
                dispatch({ type: 'setSearchValue', value: e.target.value })
              }
            />

            <button
              className={filterButtonClasses}
              onClick={() => dispatch({ type: 'toggleFilterArea' })}
            >
              <div className='m-right--8'>
                {state.filterOpen ? <MinusIcon /> : <PlusIcon />}
              </div>
              {I18n.t('filter')}
            </button>
          </div>

          {state.userCount !== undefined && (
            <span className='color--gray fontSize--14'>
              {I18n.t('groups_found_count', { total: state.userCount })}
            </span>
          )}
        </header>

        <Collapse
          isOpened={state.filterOpen}
          theme={{ collapse: styles.collapseContainer }}
        >
          <section className={styles.filterArea}>
            <div className={styles.filterGroupTypes}>
              <strong className={styles.filterLabel}>
                {I18n.t('filter_by_type')}
              </strong>
              {buildGroupTypeButtons()}
            </div>

            <div className={styles.filterGroupSize}>
              <strong className={styles.filterLabel}>
                {I18n.t('group_size')}
              </strong>
              <div className='l-flex l-flex--vAlignCenter l-flex--hSpaceBetween'>
                <input
                  type='text'
                  className={styles.rangeInput}
                  placeholder='0'
                  value={state.rangeValues.min}
                  onChange={(e) =>
                    dispatch({
                      type: 'setRangeMinValue',
                      value: e.target.value,
                    })
                  }
                />
                <MinusIcon className={styles.filterMinusIcon} />
                <input
                  type='text'
                  className={styles.rangeInput}
                  placeholder={(999000).toLocaleString()}
                  value={state.rangeValues.max}
                  onChange={(e) =>
                    dispatch({
                      type: 'setRangeMaxValue',
                      value: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className={styles.resetFilters}>
              <button
                disabled={isResetEnabled}
                className={resetButtonClasses}
                onClick={() => dispatch({ type: 'resetFilters' })}
              >
                <CloseThickIcon className='fontSize--12 m-right--8' />
                {I18n.t('reset_all')}
              </button>
            </div>
          </section>
        </Collapse>

        <div className={styles.content}>
          <ul className={styles.groupList}>
            <GroupList
              isLoading={state.isLoading}
              groups={state.groups}
              onSelectGroup={setSelectedGroup}
              selected={state.selected}
            />
            <li ref={groupListItemRef} />
            {state.isLoadingPage && (
              <li className={styles.loaderContainer}>
                <SpinnerLoader small />
              </li>
            )}
          </ul>

          <div className={styles.groupDetails}>
            <SelectedGroup
              group={state.selected}
              save={save}
              getGroupByName={getGroupByName}
              isLoading={state.isLoading}
            />
          </div>
        </div>
      </section>
    </Modal>
  );
};

AddGroupModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  save: PropTypes.func,
};

export default AddGroupModal;
