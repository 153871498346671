import './css/main.scss';

import { CeleritasConnector } from 'design-system';
import React from 'react';
import ReactDOM from 'react-dom';

import AppRoutes from '../src/components/Routes';
import * as serviceWorker from './serviceWorker';
import i18nTranslations from './utilities/i18n.en';

CeleritasConnector.init({
  translations: i18nTranslations,
});

ReactDOM.render(<AppRoutes />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
