import { defaultHeaders } from './AnalyticsAPI';
import { stringifyQueryParams as qs } from './helper';

export const baseUrl = process.env.REACT_APP_ANALYTICS_API_URL;

export const createQueryFn = (baseUrl) => {
  return ({ queryKey }) => {
    const endpoint = queryKey[0];
    const options = queryKey[1] || {};

    const path = endpoint + qs(options);
    return fetcher({
      url: baseUrl + path,
      headers: { cid: options.customer_id },
    });
  };
};

export const fetcher = async (config = {}) => {
  const { url, headers = {}, ...rest } = config;
  const res = await fetch(url, {
    ...rest,
    headers: {
      ...defaultHeaders,
      jwt: localStorage.jwt,
      ...headers,
    },
  });

  if (!res.ok) {
    throw new Error(await res.json());
  }

  return res.json();
};

export const buildEndpoint = (endpoint, queryParams = {}) => {
  const params = qs(queryParams);
  return `${endpoint}${params}`;
};
