function appendPageContent(container, children, pageName) {
  const pageSection = document.createElement('div');
  pageSection.setAttribute('id', pageName);

  children.forEach((child) => pageSection.appendChild(child.cloneNode(true)));

  container.appendChild(pageSection);
}

export function createContentForPageOne(container) {
  const dropdowns = document.getElementById('dashboardReport__dashboardDropdowns');
  const kpiBanner = document.getElementById('dashboardReport__dashboardKpiBanner');
  const topAppUsage = document.getElementById('dashboardReport__topAppUsage');
  const genericCharts = document.getElementById('dashboardReport__genericCharts');

  appendPageContent(container, [dropdowns, kpiBanner, topAppUsage, genericCharts], 'page1');
}

export function createContentForPageTwo(container) {
  const tables = document.getElementById('dashboardReport__topTables');
  appendPageContent(container, [tables], 'page2');
}

export function createContentForPageThree(container) {
  const tables = document.getElementById('dashboardReport__genericTables');
  appendPageContent(container, [tables], 'page3');
}
