/* eslint-disable max-len */
export default {
  '30d_change': '30d change',
  '75_or_higher_adoption': '75% or higher adoption',
  '50_75_adoption': '50% - 75% adoption',
  activity_feed: 'Activity feed',
  approve: 'Approve',
  approved: 'Approved',
  approved_apps: 'Approved Apps',
  approved_application: 'Approved application',
  '7 days': '7 days',
  '30 days': '30 days',
  '90 days': '90 days',
  apps_with_the_highest_number_of_sessions:
    'Apps with the highest/lowest number of sessions per user and largest increase/decrease in the number of sessions per user for the selected group and date range',
  account_list: 'Account List',
  active_users: 'Active users',
  add: 'Add',
  add_note: 'Add note',
  add_group: 'Add Group',
  add_favorite: 'Add favorite',
  add_group_to_list: 'Add %{type} to List',
  add_app: 'Add app',
  add_app_name: 'Add App: %{name}',
  add_one_or_more_apps_to_start_seeing_usage_data:
    'Add one or more apps to start seeing usage data.',
  add_it: 'Add it',
  add_your_criteria: 'Add your criteria',
  adoption: 'Adoption',
  adoption_engagement: 'Adoption & Engagement',
  after_school: 'After School',
  all_purchased_app_licenses:
    'All purchased app licenses compared to the number of licenses for the selected group and date range',
  android: 'Android',
  an_unknown_error_occured: 'An unknown error occurred.',
  an_unknown_error_occured_please_contact_admin:
    'An unknown error occurred. Please contact your administrator.',
  analytics: 'Analytics',
  analyzing_your_data: 'Analyzing your data.',
  analyzing_your_data_for_new_apps:
    'Analyzing your data for new apps. This can take a few minutes...',
  annual_cost: '* Annual cost',
  annual: 'Annual',
  dashboard: 'Dashboard',
  app: 'App',
  apps_counter: '%{apps} Apps',
  app_analytics_logo: 'App Analytics Logo',
  app_detected: 'App Detected',
  app_manager: 'App Manager',
  track_app_confirmation:
    'App data is now being processed and will appear in your reports within the next 24 hours.',
  app_name: 'App name',
  app_name_no_longer_than_50_characters:
    'Name cannot be longer than 50 characters',
  app_name_logo: '%{name} Logo',
  app_name_placeholder: 'ABCYa',
  apps_for_review: 'Apps for review',
  app_spotlight: 'App Spotlight',
  app_type: 'App type',
  app_usage: 'App Usage',
  app_usage_details: 'App usage details',
  app_urls: 'App URLs',
  app_urls_placeholder: 'https://www.abcya.com/games/',
  app_usage_of_students_based_on_time_of_day:
    'App usage breakdown of students based on time of day',
  app_submitted: 'App submitted',
  the_selected_apps_have_been_moved:
    'The selected app(s) have been moved to %{status}.',
  approved_free_apps_for:
    'Approved free apps for selected group, for specified time',
  apps: 'Apps',
  apps_above_75_usage: 'Apps above 75% usage',
  apps_count: '%{count} Apps',
  apps_detected_that_are_not_currently_tracked:
    'apps detected that are not currently tracked. Would you like to start tracking them now?',
  apps_with_50_75_usage: 'Apps with 50% - 75% usage',
  apps_below_50_usage: 'Apps below 50% usage',
  apps_by_license_usage: 'Apps by License Usage',
  apps_by_adoption_level: 'Apps by Adoption Level',
  apps_reviewed_for_privacy: 'Apps reviewed for privacy',
  apps_tracked: 'Apps tracked',
  archive: 'Archive',
  archived: 'Archived',
  are_you_sure_you_want_to_untrack:
    'Are you sure you want to untrack %{appName}?',
  are_you_sure_you_want_to_remove:
    'Are you sure you want to remove *%{groupName}* from the list?',
  avg_cost_user: 'Avg. Cost / User',
  at_least_one_url_is_required: 'At least one url is required',
  attendance: 'Attendance',
  back_to_app_overview: 'Back to App Overview',
  back_to_data_compliance: 'Back to data compliance',
  back_to_detected: 'Back to detected apps',
  back_to_free_apps: 'Back to free apps',
  back_to_paid_apps: 'Back to paid apps',
  back_to_overview: 'Back to overview',
  below_50_adoption: 'Below 50% adoption',
  billing_cycle: '* Billing cycle',
  borderline: 'Borderline',
  build_chart: 'Build chart',
  build_your_chart: 'Build your chart',
  cancel: 'Cancel',
  certificates: 'Certificate(s)',
  certifications: 'Certifications',
  edit_app: 'Edit %{appName}',
  certifications_pledges_description:
    'Third-party compliance certifications or pledges',
  characters_remaining: '%{count} characters remaining',
  check_back_in_24_hours: 'Check back in 24 hours.',
  child_groups_count: 'Child groups (%{count})',
  clear: 'Clear',
  close: 'Close',
  chart_builder_tooltip:
    'View the cost of unused apps, or unique user visits, for either a single application or a group of applications. Compare either cost or visit metrics spanning 7, 30, or 90 days.',
  chromebook: 'Chromebook',
  chart_builder: 'Chart Builder',
  chart_builder_loader: 'chart builder loader',
  compare_prior_period: 'Compare prior period',
  compliance_categories: 'Compliance categories',
  continue: 'Continue',
  contract_details: 'Contract details',
  this_app_does_not_have_any_certifications_or_pledges:
    'This app does not have any certifications or pledges',
  compliant: 'Compliant',
  collecting_data: 'Collecting data',
  copied: 'Copied!',
  contract_type: '* Contract type',
  copied_to_clipboard: 'Copied to clipboard!',
  copy: 'Copy',
  cost: 'Cost',
  cost_of_licenses_used: 'Cost of Licenses Used',
  certifications_pledges: 'Certifications/Pledges',
  cost_of_purchased_licenses: 'Cost of purchased licenses',
  cost_of_unused_app_licenses: 'Cost of unused app licenses',
  cost_of_unused_app_licenses_for:
    'Cost of unused app licenses for a selected group and date range',
  cost_of_unused_licenses: 'Cost of unused licenses',
  compliance_review:
    'The Lightspeed Systems Privacy Team will review %{app} data privacy compliance and email you when the ratings are updated.',
  count: 'Count',
  check_back_once_app_data_has_been_captured:
    'Check back once app data has been captured.',
  customize_ratings: 'Customize ratings',
  currency_per_license: '%{symbol} per license',
  currency_total: '%{symbol} total',
  current_interval: 'Current Interval',
  currently_tracking: 'Currently tracking %{appName}',
  customer_cannot_submit_the_same_app:
    'Customer cannot submit the same app request more than once',
  data_privacy: 'Data Privacy',
  data_compliance: 'Data Compliance',
  data_explorer: 'Data Explorer',
  data_privacy_grade: 'Data Privacy Grade',
  data_retention: 'Data retention',
  data_retention_description: 'District policies for retaining student data',
  data_security: 'Data security',
  data_security_description:
    'Administrative, technical, and physical safeguards',
  data_is_loading: 'Data is loading',
  day: 'Day',
  default_rating: 'Default rating',
  daily_active_users: 'Daily active users',
  daily_during_vs_after_school_usage: 'Daily During vs. After School Usage',
  daily_sessions: 'Daily Sessions',
  daily_user_activity: 'Daily User Activity',
  delete: 'Delete',
  date: 'Date',
  date_range: 'Date Range',
  detected_apps: 'Detected apps',
  developer: 'Developer',
  developers_website: "Developer's website",
  developer_website_no_longer_than_500_characters:
    "Developer's website name can't be longer than 500 characters",
  developers_website_placeholder: 'https://abcya.com',
  device_tracking: 'Device tracking',
  distribution_of_cost: 'Cost Distribution',
  district_wide: 'District-wide',
  district_overview: 'District Overview',
  dont_see_the_app_you_are_looking_for:
    "Don't see the app you are looking for?",
  disable_tracking_alert_message:
    'This will disable device tracking and the Geolocation feature. Do you want to continue?',
  dont_see_an_app_in_the_list:
    'Don’t see an app in the list? Let us know, so we can add it.',
  Dont_show_show_this_message_again: 'Don’t show this message again',
  download: 'Download',
  drill_down: 'Drill Down',
  during_school: 'During School',
  edit: 'Edit',
  embed_approved_app_list: 'Embed Approved App List',
  embed_list: 'Embed list',
  embed_list_of_approved_apps_note:
    "Embed a list of your district's approved apps with this code.",
  email: 'Email',
  enable_tracking: 'Enable tracking',
  engagement: 'Engagement',
  engagement_spotlight: 'Engagement Spotlight',
  engagement_per_group: 'Engagement per group',
  enter_a_date: 'Enter a date',
  enter_a_number: 'Enter in a number',
  enter_a_valid_website: 'Enter a valid website',
  enter_an_app_name: 'Enter an app name',
  enter_only_valid_urls: 'Enter only valid URLs',
  error: 'Error',
  error_loading_map: 'Error loading Google Maps',
  are_you_sure: 'Are you sure?',
  fail: 'Fail',
  filter: 'Filter',
  flag_app: 'Flag App',
  forgot_password: 'Forgot Password?',
  free: 'Free',
  free_app: 'Free App',
  free_apps: 'Free Apps',
  free_apps_in_use: 'Free apps in use',
  free_apps_removal_warning:
    'An app must be a paid app in order to show the cost metric. You have selected one or more free apps in the list.  These apps will be removed from the app list. Do you want to proceed?',
  free_apps_tooltip: 'All detected apps for which there are no paid licenses',
  from_cdw: 'From CDW',
  google: 'Google',
  group: 'Group',
  group_is_in_list: '%{type} is in list',
  group_details: 'Group Details',
  group_does_not_contain_groups: '%{name} does not contain any groups.',
  group_does_not_contain_users: '%{name} does not contain any users.',
  group_found_count: '1 group found',
  groups_found_count: '%{total} groups found',
  group_loading: 'Group loading',
  group_size: 'Group Size',
  filter_by_type: 'Filter by Type',
  groups_loading: 'Groups loading',
  help: 'Help',
  hi: 'Hi',
  high: 'High',
  highest: 'Highest',
  highest_adoption: 'Highest adoption',
  highest_sessions_per_user: 'Highest number of sessions per user',
  icon_list: 'Icon List',
  identified: 'Identified',
  identified_apps_are_ready_to_be_tracked:
    'Identified apps that are ready to be tracked',
  indicates_a_required_field: '* Indicates a required field',
  individual_user_engagement_for_the_selected_group:
    'Individual user engagement, for the selected group and date range, measured using the district-wide average on a rolling seven-day basis',
  information_usage: 'Information usage',
  information_usage_description:
    'User data is not used for promotional purposes.',
  insufficient_privileges_please_contact_admin:
    'Insufficient privileges, please contact your administrator.',
  invalid: 'invalid: %{urls}',
  invalid_date: 'Invalid date',
  ios: 'iOS',
  keep: 'Keep',
  keep_tabs_on_your_devices: 'Keep tabs on your devices',
  largest_decrease: 'Largest decrease',
  largest_decrease_in_sessions_per_user:
    'Largest decrease in sessions per user',
  largest_increase: 'Largest increase',
  largest_increase_in_sessions_per_user:
    'Largest increase in sessions per user',
  last_7_days: 'Last 7 days',
  last_30_days: 'Last 30 days',
  last_90_days: 'Last 90 days',
  last_30d: 'Last 30d',
  last_d: 'Last %{range}d',
  learn_more: 'Learn more',
  least_usage: 'Least Usage',
  leave_a_note_here: 'Leave a note here.',
  less_engaged: 'Less Engaged',
  license_count: 'License count',
  license_usage: 'License usage',
  licensed: 'Licensed',
  licenses: 'Licenses',
  licenses_user: 'Licenses / User',
  licenses_used: 'Licenses used',
  licenses_purchased: 'Licenses Purchased',
  loading_apps: 'Loading apps...',
  loading: 'Loading',
  login_link_has_expired: 'Login link has expired.',
  low: 'Low',
  lowest: 'Lowest',
  lowest_adoption: 'Lowest adoption',
  lowest_rating: 'Lowest Rating',
  lowest_sessions_per_user: 'Lowest number of sessions per user',
  lightspeed_systems: 'Lightspeed Systems',
  lightspeed_analytics: 'Lightspeed Analytics',
  macos: 'MacOS',
  map: 'Map',
  max_license_message:
    'You must specify a license number of less than %{maxValue}.',
  max_cost_message: 'You must specify a cost of less than $%{maxValue}.',
  request_review_success_text:
    ' is undergoing a compliance review with the Lightspeed Systems team. You will receive an email when the ratings are updated.',
  microsoft: 'Microsoft',
  moderate: 'Moderate',
  monthly: 'Monthly',
  most_activity_per_user: 'Most Activity per User',
  more_engaged: 'More Engaged',
  most_usage: 'Most Usage',
  most_users: 'Most Users',
  multi_select_placeholder: 'e.g. Adobe Draw',
  name: 'Name',
  device_location: 'Device Location',
  name_has_been_untracked: '%{name} has been untracked.',
  name_was_successfully_flagged: '*%{name}* was successfully flagged.',
  new_apps: 'New Apps',
  newly_detected_apps_for_the_selected_group:
    'Newly detected apps for the selected group and date range',
  no: 'No',
  non_compliant: 'Non-compliant',
  native_app: 'Native app',
  not_tracking_app: 'Not tracking %{appName}',
  no_apps_detected: 'No apps detected',
  no_apps_found: 'No apps found',
  no_apps_to_display: 'No app to display',
  no_apps_match_your_search:
    'No apps match your search. Check your spelling or try a different term.',
  no_data: 'No Data',
  no_data_to_display: 'No data to display',
  no_groups_found: 'No groups found',
  no_users_found: 'No users found',
  no_notes: 'No notes found',
  no_results: 'No results found',
  no_results_message: 'Try selecting a different date range or group',
  no_certificates_associated:
    'No privacy pledges or certificates are associated with this app.',
  note_ratings_are_based_on_the_privacy_policy:
    'Note: ratings are based on the privacy policy published by the developer at the time of review.',
  not_set: 'Not set',
  not_engaged: 'Not Engaged',
  not_tracking: 'Not Tracking',
  number_is_invalid: 'Number is invalid',
  number_of_licenses_purchased: '* Number of licenses purchased',
  number_of_number: '%{first} of %{second}',
  number_of_active_users_within_the_selected_group:
    'Number of active users within the selected group and date range',
  district_wide_apps_that_need_to_be_approved_or_rejected:
    'District-wide apps that need to be approved or rejected',
  number_of_users: 'Number of Users',
  optional: 'optional',
  organization_compliance: 'Organization compliance',
  other: 'Other',
  others: 'Others',
  Ou: 'OU',
  OuGroup: 'OU',
  operating_system: 'Operating systems',
  Group: 'Group',
  geo_location: 'Geolocation',
  geo_location_coming_soon: 'Geolocation (Coming Soon)',
  School: 'School',
  opt_in_message1:
    'Analytics can map your devices, flag those outside a geographic range and provide insights on their usage.',
  opt_in_message2:
    'To get stared, you must enable device tracking of your district’s devices.',
  org_compliance: 'Org compliance',
  org_compliance_description: 'Compliance with district’s privacy requirements',
  paid_apps: 'Paid Apps',
  pass: 'Pass',
  pending: 'Pending',
  per_license: 'Per license',
  percentage: 'Percentage',
  password: 'Password',
  past_30_days: 'Past 30 days',
  percent_change: '% Change',
  please_check_back: 'Please check back in 48 hours',
  please_dont_refresh: 'Please don’t refresh. This may take a few moments.',
  compliance: 'Compliance',
  compliance_details: 'Compliance details',
  paid: 'Paid',
  previous_interval: 'Previous Interval',
  privacy_grade: 'Privacy grade',
  privacy_grade_by_license_count: 'Privacy grade by license count',
  privacy_policy: 'Privacy policy',
  privacy_policy_description:
    'Addresses the three COPPA requirements: operator contact information, information collected from children, and parental oversight',
  privacy_rating: 'Privacy Rating',
  privacy_reviewed_apps: 'Privacy Reviewed Apps',
  purchased_licenses: 'Purchased Licenses',
  purchased_licenses_by_cost_and_count: 'Purchased Licenses by Cost and Count',
  rating_factors: 'Rating factors',
  request_review: 'Request review',
  remove: 'Remove',
  remember_me: 'Remember me',
  renewal_date: '* Renewal date',
  required: 'Required',
  reset: 'Reset',
  reset_ratings: 'Reset %{app}?',
  reset_compliance_ratings_back:
    'Reset the compliance ratings back to the Lightspeed Systems default. You cannot undo this action.',
  reset_all: 'Reset All',
  save_note: 'Save note',
  reject: 'Reject',
  rejected: 'Rejected',
  save: 'Save',
  reset_to_default: 'Reset to default',
  search_free_apps: 'Search free apps...',
  search_apps: 'Search apps...',
  search_by_group_keyword: 'Search by group keyword...',
  search_paid_apps: 'Search paid apps...',
  sessions: 'Sessions',
  sessions_user: 'Sessions / User',
  selected: '%{count} Selected',
  share: 'Share',
  shared_by: 'Shared by',
  show_archived: 'Show archived',
  select_a_group: 'Select a group',
  select_an_app: 'Select an app',
  select_metric: 'Select metric',
  select_range: 'Select range',
  select_up_to_five: 'Select up to five',
  selection_limit_reached: 'Selection limit reached',
  select_your_range_metric:
    'Select your range, metric, app(s), and group to build your chart.',
  settings: 'Settings',
  sign_in: 'Sign In',
  sign_out: 'Sign out',
  sorry_still_loading: 'Sorry! Still loading your data',
  status: 'Status',
  student_activity_by_day_of_week: 'Student activity by day of week',
  student_activity_by_time_of_day: 'Student activity by time of day',
  success: 'Success',
  submit: 'Submit',
  submit_app: 'Submit App',
  submit_app_for_review: 'Submit app for review',
  submit_an_app_for_review: 'Submit an app for review',
  terms_of_use: 'Terms of use',
  time_of_day: 'Time of day',
  thanks_for_sharing_the_app_details:
    'Thanks for sharing the app details. We’ll review it soon.',
  the_link_below_will_allow_shareonly:
    'The link below will allow read-only access to Analytics reports for 72 hours.',
  there_are_no_paid_apps_being_tracked: 'There are no paid apps being tracked.',
  no_data_untracked_app: 'There is no data for apps that are not tracked.',
  this_can_take_a_few_minutes: 'This can take a few minutes...',
  toggle_ratings_base:
    'Toggle ratings based on your organization’s vendor contract.',
  top_app_usage: 'Top App Usage',
  apps_used_most_frequently:
    'Apps used most frequently in a rolling seven-day period for the selected group and date range',
  top_factors: 'Top Factors',
  trending_apps: 'Trending Apps',
  trending_unapproved_apps: 'Trending Unapproved Apps',
  top_10_trending_unapproved_apps_among_active_users:
    'Top 10 trending unapproved apps among active users compared to the previous time period, for the selected group and date range',
  top_10_trending_apps_among_active_users:
    'Top 10 trending apps among active users compared to the previous time period, for the selected group and date range',
  total: 'Total',
  total_annual: 'Total Annual',
  total_annual_cost: 'Total Annual Cost',
  all_detected_apps_within_a_selected_group:
    'All detected apps within a selected group and date range',
  total_approved_apps_with_privacy_reviews:
    'Total approved apps with privacy reviews',
  total_number: 'Total: %{total}',
  total_requests: 'Total Requests',
  total_cost: 'Total cost',
  tooltip_paid_apps_title: 'All apps for which there are paid licenses',
  tooltip_submit_app_for_review:
    'Don’t see an app in the list? Let us know, so we can add it.',
  tooltip_paid_apps_cost_distribution:
    'Total cost of all licenses purchased by the district and a breakdown of the top 5 most expensive apps',
  tooltip_paid_apps_daily_sessions: 'Daily user sessions',
  tooltip_paid_apps_app_spotlight:
    'District-wide apps that have the highest/lowest adoption rates and the largest increase/decrease in adoption over the last 30 days',
  tooltip_paid_apps_app_usage:
    'Apps organized by adoption and engagement to help identify outliers',
  tracked: 'Tracked',
  tracking: 'Tracking',
  tracked_paid_app: 'Tracked Paid App',
  tracked_paid_apps: 'Tracked Paid Apps',
  tracked_free_app: 'Tracked Free App',
  tracked_free_apps: 'Tracked Free Apps',
  trending_down: 'Trending Down',
  trending_up: 'Trending Up',
  Topic: 'Topic',
  try_selecting_different:
    'Try selecting different criteria or check back once more data has been captured.',
  type: 'Type',
  the_username_password_you_entered_is_invalid:
    'The username/password you entered is invalid.',
  the_unique_number_of_daily_app_sessions:
    'The unique number of daily app sessions for the selected group and date range',
  unapproved: 'Unapproved',
  unique_sessions: 'Unique Sessions',
  unique_sessions_per_day: 'Unique sessions per day',
  unique_number_apps_visited_by_students:
    'Unique number of apps visited by students, broken out by day of week',
  untrack: 'Untrack',
  untrack_app: 'Untrack app',
  untrack_name: 'Untrack: %{name}',
  stop_tracking: 'Stop tracking',
  unused_license_cost: 'Unused license cost',
  unused_license_cost_percentage: 'Unused license cost percentage',
  unused_purchased_licenses_by_cost_and_count:
    'Unused purchased licenses by cost and count',
  update: 'Update',
  updated: 'Updated',
  unknown: 'Unknown',
  include_archive_apps: 'Include archived apps in the App Manager table',
  untracked_apps: 'Untracked apps',
  user: 'User',
  user_sessions: 'User Sessions',
  users: 'Users',
  user_account: 'User account',
  untrack_confirmation_message:
    '%{appName} will no longer appear in your reports or dashboard.',
  last_updated_by: 'Last updated by %{user}',
  on_date: 'on %{date}',
  users_count: '%{count} users',
  users_per_free_app: 'Users per Free App',
  users_per_group: 'Users per group',
  users_per_paid_app: 'Users per Paid App',
  unique_number_of_sessions: 'Unique number of sessions',
  view: 'View',
  view_apps: 'View Apps',
  view_dashboard: 'View Dashboard',
  view_more: 'View more',
  view_list: 'View list',
  request_already_received:
    'Request already received. You are providing a duplicate submission.',
  visits: 'Visits',
  visits_to_these_urls_will_be_used_to_track_app_usage:
    'Visits to these URLs will be used to track app usage.',
  would_you_like_to_flag_name: 'Would you like to flag *%{name}*?',
  write_a_note: 'Write a note',
  wrote_a_note: '%{name} wrote a note',
  yes: 'Yes',
  web_app: 'Web app',
  windows: 'Windows',
  you_have_apps: 'You Have Apps!',
  you_are_about_to_delete_this_note: 'You are about to delete this note.',
  your_app_was_added_successfully:
    'Your app was added successfully. App data is now being processed and will appear in your reports within the next 24 hours.',
  your_app_was_successfully_submitted_for_review:
    'Your app was successfully submitted for review.',
  your_app_was_updated_successfully:
    'Your app was updated successfully. App data is now being processed and changes will appear in your reports within the next 24 hours.',
  your_rating: 'Your rating',
  y_axis: 'Y axis',
  x_axis: 'X axis',
};
