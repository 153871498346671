/* eslint-disable no-console */
import endOfDay from 'date-fns/endOfDay';

// this will set the state of localStorage while clearing out old states
export const localStorageSaveState = (localStorageKey, start, end, path, data) => {
  const savedStates = getTrimmedStates(localStorageKey);
  const savedStateIndex = savedStates.findIndex((state) => (state.start === start && state.end === end));
  let savedState = savedStateIndex > -1 && savedStates[savedStateIndex];

  if (!savedState) {
    savedState = {
      start,
      end,
      expiresIn: endOfDay(new Date()),
      data: {},
    };
  }

  let currentPointer = savedState.data;
  const lastPathIndex = path.length - 1;
  path.forEach((key, index) => {
    if (typeof currentPointer !== 'string') { // for when currentPointer isn't received as an object. Causing a crash
      currentPointer[key] = currentPointer[key] || {};
      if (index !== lastPathIndex) {
        currentPointer = currentPointer[key];
      } else {
        currentPointer[key] = data;
      }
    }
  });

  if (savedStateIndex === -1) {
    savedStates.push(savedState);
  } else {
    savedStates[savedStateIndex] = savedState;
  }

  localStorage.setItem(localStorageKey, JSON.stringify(savedStates));
};

export const localStorageLoadState = (localStorageKey, start, end, path) => {
  let savedStates = [];
  try {
    const stringified = localStorage.getItem(localStorageKey);
    savedStates = stringified ? JSON.parse(stringified) : [];
  } catch (e) {
    console.error(e);
  }

  const savedState = savedStates.find((state) => (state.start === start && state.end === end)) || { data: {} };

  let currentPointer = savedState.data;
  const hasData = path.every((key) => {
    if (currentPointer && currentPointer[key]) {
      currentPointer = currentPointer[key];
      return true;
    }
    return false;
  });

  return hasData ? currentPointer : null;
};

export const localStorageRemoveState = (localStorageKey, start, end, path) => {
  let savedStates = [];
  try {
    const stringified = localStorage.getItem(localStorageKey);
    savedStates = stringified ? JSON.parse(stringified) : [];
  } catch (e) {
    console.error(e);
  }

  const savedState = savedStates.find((state) => (state.start === start && state.end === end)) || { data: {} };
  let currentPointer = savedState.data;
  const lastPathIndex = path.length - 1;

  path.some((key, index) => {
    if (currentPointer === undefined) {
      return true;
    }

    if (index === lastPathIndex) {
      delete currentPointer[key];
      return true;
    }

    currentPointer = currentPointer[key];
    return false;
  });

  localStorage.setItem(localStorageKey, JSON.stringify(savedStates));
};

const getTrimmedStates = (key) => {
  const now = new Date();
  let saved = [];
  try {
    const stringified = localStorage.getItem(key);
    saved = stringified ? JSON.parse(stringified) : [];
  } catch (e) {
    console.error(e);
  }

  return saved.filter((item) => new Date(item.expiresIn) > now);
};