import '../css/layout/_main_layout.scss';

import { HelpIcon } from 'design-system';
import React from 'react';
import { Helmet } from 'react-helmet';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';

import { baseUrl, createQueryFn } from '../utilities/fetcher';
import I18n from '../utilities/i18n';
import {
  App as AppDetail,
  ApprovedAppsList,
  ChartBuilder,
  Dashboard,
  DataPrivacy,
  DataPrivacyDetails,
  DetectedApps,
  FreeApps,
  PaidAppsDashboard,
  PaidAppsTableView,
  ProcessSignIn,
  SignIn,
} from './asyncModules';
import Debug from './common/Debug';
import GoogleAnalytics from './common/GoogleAnalytics';
import { UserActionsProvider } from './contexts/UserActionsContext';
import { UserProvider } from './contexts/UserContext';
import PrivateRoute from './PrivateRoute';

const isProduction = process.env.REACT_APP_ENV === 'production';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: isProduction,
      retry: isProduction ? 2 : false,
      queryFn: createQueryFn(baseUrl),
    },
  },
});

const App = () => {
  return (
    <>
      <Helmet>
        {/* OneTrust Cookies Consent Notice start for analytics.relay.school */}
        <script
          src='https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
          type='text/javascript'
          charset='UTF-8'
          data-domain-script='293abb78-7e94-449c-a2e4-45696b611fc9'
        />
      </Helmet>
      <UserProvider>
        <UserActionsProvider>
          <GoogleAnalytics>
            <QueryClientProvider client={queryClient}>
              <Outlet />
              <ReactQueryDevtools />
            </QueryClientProvider>
          </GoogleAnalytics>

          <a
            rel='noopener noreferrer'
            target='_blank'
            title={I18n.t('help')}
            href={process.env.REACT_APP_ANALYTICS_HELP_URL}
          >
            <div className='help-bubble'>
              <HelpIcon />
            </div>
          </a>
        </UserActionsProvider>
      </UserProvider>
    </>
  );
};

const AppRoutes = () => (
  <BrowserRouter>
    <Routes>
      <Route path='/approved-apps' element={<ApprovedAppsList />} />
      <Route path='/' element={<App />}>
        <Route
          path='signing-in'
          element={<PrivateRoute component={ProcessSignIn} />}
        />
        <Route
          path=':guid/dashboard'
          element={<PrivateRoute component={Dashboard} />}
        />
        <Route
          path=':guid/data-compliance'
          element={<PrivateRoute component={DataPrivacy} />}
        />
        <Route
          path=':guid/data-compliance/:app_guid'
          element={<PrivateRoute component={DataPrivacyDetails} />}
        />
        <Route
          path=':guid/apps/detected'
          element={<PrivateRoute component={DetectedApps} readOnly />}
        />
        <Route
          path=':guid/paid-apps'
          element={<PrivateRoute component={PaidAppsDashboard} />}
        />
        <Route
          path=':guid/paid-apps-list'
          element={<PrivateRoute component={PaidAppsTableView} />}
        />
        <Route
          path=':guid/free-apps'
          element={<PrivateRoute component={FreeApps} />}
        />
        <Route
          path=':guid/apps'
          element={<PrivateRoute component={PaidAppsTableView} />}
        />
        <Route
          path=':guid/apps/:app_guid'
          element={<PrivateRoute component={AppDetail} />}
        />
        <Route
          path=':guid/chart-builder'
          element={<PrivateRoute component={ChartBuilder} />}
        />
        {process.env.REACT_APP_ENV === 'development' && (
          <Route
            path=':guid/debug'
            element={<PrivateRoute component={Debug} />}
          />
        )}
        <Route path='/' element={<SignIn />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

export default AppRoutes;
