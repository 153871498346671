import html2canvas from 'html2canvas';
import { format } from 'date-fns';

export function clearElement(container) {
  container.querySelectorAll('*').forEach((n) => n.remove());
}

export async function generateImage(element, imgWidth) {
  const canvas = await html2canvas(element);
  const imgHeight = (canvas.height * imgWidth) / canvas.width;

  const imgData = canvas.toDataURL('image/png');
  return { imgData, canvas, imgWidth, imgHeight };
}

export const imgWidth = 210;

export const imgPositions = { x: 3, y: 25 };

export function generateCreatedAtValue() {
  const createdAt = `Created at: ${format(new Date(), 'MM/dd/yyyy HH:mm:ss zzzz', {
    awareOfUnicodeTokens: true,
  })}`;
  return createdAt;
}

export async function setPdfImage(pdfInstance, element, positions = {}) {
  const { x = imgPositions.x, y = imgPositions.y } = positions;
  const { imgData, imgHeight } = await generateImage(element, imgWidth);
  pdfInstance.addImage(imgData, 'PNG', x, y, imgWidth, imgHeight, '', 'FAST');
  return { imgData, imgHeight };
}
