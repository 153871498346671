/* eslint-disable multiline-ternary */
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import Layout from '../components/common/Layout';
import { AnalyticsProvider } from './contexts/AnalyticsContext';
import { DistrictProvider } from './contexts/DistrictContext';
import { UserContext } from './contexts/UserContext';

const PrivateRoute = ({
  component: Component,
  computedMatch = {},
  readOnly,
  ...restProps
}) => {
  const userContext = useContext(UserContext);
  const { pathname } = useLocation();

  const isAuthenticated = Boolean(userContext.user);
  const redirectUrl = !isAuthenticated && pathname;
  const isReadOnly = readOnly && userContext?.user?.readOnly;
  const params = useParams();

  if (isAuthenticated && !isReadOnly) {
    return (
      <DistrictProvider
        computedMatch={{ params, url: pathname, ...computedMatch }}
      >
        <AnalyticsProvider>
          <Layout>
            <Component {...restProps} />
          </Layout>
        </AnalyticsProvider>
      </DistrictProvider>
    );
  }

  return <Navigate to={{ pathname: '/' }} state={{ from: redirectUrl }} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  computedMatch: PropTypes.object,
  readOnly: PropTypes.bool,
};

export default PrivateRoute;
