import { cn } from 'design-system';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { UserActionsContext } from '../contexts/UserActionsContext';
import Header from './Header/Header';
import NavSidebar from './NavSidebar';

const Layout = ({ children }) => {
  const { mobileMenuState } = useContext(UserActionsContext);
  const { pathname } = useLocation();
  const wrapper = useRef(null);

  useEffect(() => {
    if (wrapper.current) {
      wrapper.current.scrollTo(0, 0);
    }
  }, [pathname, wrapper]);

  const mainContentWrapperClasses = cn('mainContentWrapper', {
    'mainContentWrapper--fixed': mobileMenuState,
  });

  return (
    <div className='appWrapper'>
      <NavSidebar />
      <section ref={wrapper} className={mainContentWrapperClasses}>
        <Header />
        {children}
      </section>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.object,
};

export default Layout;
